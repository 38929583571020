<template>
  <v-card
    v-if="product"
    class="ma-2 pa-2 light-blue lighten-5"
    width="200"
    height="200"
  >
    <v-img
      v-if="dishImage(product)"
      contain
      height="100"
      :src="dishImage(product)"
    >
    </v-img>
    <v-card-title>
      <h4>{{ product.name }}</h4>
    </v-card-title>
  </v-card>
</template>

<script>
export default {
  props: {
    product: {
      Type: Object,
      required: true
    }
  },
  methods: {
    dishImage(dish) {
      return dish && dish.photos && dish.photos.length > 0
        ? dish.photos[0].imageUrl
        : null
    }
  }
}
</script>
