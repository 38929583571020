<template>
  <v-container grid-list-xs>
    <v-layout row wrap>
      <v-flex xs12>
        <h3>Menus</h3>
      </v-flex>
    </v-layout>
    <v-toolbar-items>
      <v-btn
        text
        @click="$vuetify.goTo('#t' + index)"
        v-for="(m, index) in menus"
        :key="m.id"
      >
        Item {{ m.name }}
      </v-btn>
    </v-toolbar-items>

    <v-toolbar color="info" dark tabs>
      <v-text-field
        append-icon="mic"
        class="mx-3"
        flat
        label="Search"
        prepend-inner-icon="search"
        solo-inverted
      ></v-text-field>

      <template v-slot:extension> </template>
    </v-toolbar>

    <v-container grid-list-xs>
      <div v-for="(menu, index) in menus" :key="index">
        <v-layout row wrap>
          <v-flex xs12 sm3 :id="'t' + index">
            <h3>{{ menu.name }}</h3>
          </v-flex>
          <v-flex xs12 sm6>
            {{ menu.description }}
          </v-flex>
        </v-layout>
        <v-layout row wrap v-for="(item, id) in menu.items" :key="id">
          <v-flex xs12 sm3>
            <product-view :product="getProduct(item)"></product-view>
          </v-flex>
        </v-layout>
      </div>
    </v-container>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import ProductView from './MiniProductView'

export default {
  components: { ProductView },
  data() {
    return {
      menus: null,
      tabs: null,
      text:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
    }
  },
  computed: {
    ...mapGetters({
      profile: 'userProfile',
      loading: 'loading',
      chefDishList: 'chefDishList'
    })
  },
  mounted() {
    this.$store
      .dispatch('loadDishList', {
        force: true,
        id: this.$route.params.id
      })
      .then(() => {
        this.$store.dispatch('loadMenus', this.$route.params.id).then(list => {
          // remove empty menus (in case)
          if (list) {
            list = list.filter(m => m.items && m.items.length > 0)
            this.menus = list
          }
        })
      })
  },
  methods: {
    menuChange(data) {
      this.$vuetify.goTo('#t' + data)
    },
    getProduct(id) {
      let p = this.chefDishList.filter(p => p.id == id)
      if (p && p.length > 0) {
        return p[0]
      }
      console.warn(`Can not load product: ${id}`)
      return
    }
  }
}
</script>
